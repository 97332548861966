<!-- 商品销售统计详情 -->

<template>
  <div class="saleProductStatisticsDetail">
    <back />
    <div class="globle_border" v-loading="loading">

      <!-- echart -->
      <el-date-picker v-model="chartTime" type="month" value-format="yyyyMM" placeholder="选择月份"
        @change="changeChartTime"></el-date-picker>
      <div v-show="shopSale.length > 0" class="echarts">
        <div class="echart" id="main" style="width: 1600px; height: 400px;"></div>
      </div>
      <el-empty v-show="shopSale.length == 0" description="暂无图表数据"></el-empty>

      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="productNameValue" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="400" @sort-change="handleSortChange">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopCode" label="商超编号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accumulatedDelivery"
            label="累计送货"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="currentInventory" label="目前库存"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="saleCount" label="销售数量"
            sortable="custom"></el-table-column>
          <el-table-column min-width="130" show-overflow-tooltip prop="saleAmount" label="销售金额(元)"
            sortable="custom"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="saleProportion" label="销售金额占比">
            <template slot-scope="scope">
              <span v-if="scope.row.saleProportion !== null && scope.row.saleProportion !== undefined">
                {{ scope.row.saleProportion === 0 ? '0%' : scope.row.saleProportion + '%' }}
              </span>
            </template>
          </el-table-column>

        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination class="pagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    // 初始化 chartTime 为当前月份
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = ('0' + (now.getMonth() + 1)).slice(-2); // getMonth 返回的月份是从0开始的，所以需要+1，并格式化为两位数
    return {
      loading: false,
      monthValue: '', // 搜索月份
      productNameValue: '', // 搜索商品名称
      // 表格数据
      tableData: [

      ],
      productId: null,
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）

      // echarts
      shopSale: [],
      chartTime: `${currentYear}${currentMonth}`,
      myChart: null,

      // 排序
      saleCountSort: '', // 销售数量排序
      saleAmountSort: '', // 销售金额排序
    }
  },
  created() {
    this.productId = this.$route.query.productId;
    this.getProductSaleDetailList();
  },
  mounted() {

    // 注意调用顺序，先初始化echarts才给echarts赋值
    this.initEcharts()
    this.setEchartsOption()
  },

  methods: {
    // 获取列表
    getProductSaleDetailList() {
      this.loading = true;
      let params = {
        productName: this.productNameValue || null, // 搜索商超名称
        productId: this.productId || null,
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        saleCount: this.saleCount || null,//销售数量排序...
        saleAmount: this.saleAmount || null,//销售金额排序...
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.getProductSaleDetailList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleSortChange({ prop, order }) {
      console.log(prop, order);
      this.saleCount = '' // 销售数量
      this.saleAmount = '' // 销售金额
      // prop 是排序的列名，order 是排序方式，'ascending' 或 'descending'
      function setSortOrder(prop, order) {
        if (order == 'ascending') {
          return 'asc';
        } else if (order == 'descending') {
          return 'desc';
        } else {
          return '';
        }
      }

      if (prop == 'saleCount') {
        this.saleCount = setSortOrder(prop, order);
      }
      if (prop == 'saleAmount') {
        this.saleAmount = setSortOrder(prop, order);
      }
      // 在这里你可以根据 prop 和 order 去后端请求排序后的数据，或者直接在前端数据上进行排序
      this.getProductSaleDetailList()
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getProductSaleDetailList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getProductSaleDetailList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getProductSaleDetailList();
    },
    // 时间搜索
    handleSelect(value) {
      this.clearData();
      this.monthValue = value;
      this.getProductSaleDetailList();
    },
    changeChartTime() {
      if (!this.myChart) this.initEcharts()
      this.setEchartsOption()
    },
    // 初始化echarts
    initEcharts() {
      var chartDom = document.getElementById('main');
      this.myChart = echarts.init(chartDom);
    },
    setEchartsOption() {
      let params = {
        statPeriod: this.chartTime, // 统计时期（如2024、202401、20240108）
        productId: this.productId || null,
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
      };
      this.$axios.get(this.$api.getProductSaleDetailPicChart, { params }).then((res) => {
        if (res.data.code == 100) {
          this.shopSale = res.data.result
          let result = res.data.result.map(e => {
            return {
              ...e,
              name: e.shopName, // 名称
              saleCount: e.saleCount,  // 销售数量
              value: e.saleAmount, // 销售金额
              saleProportion: e.saleProportion + '%', // 占比
            }
          })
          var option = {
            title: {
              left: 'center'
            },
            color: ['#f76848', '#74bdcf', '#7db7f7', '#e7bcf3', '#ffa500', '#4dd0e1', '#ff7f50', '#c0c0c0', '#800080', '#ffd700', '#228b22', '#ff4500'],
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                let name = params.data.name;
                let saleCount = params.data.saleCount;
                let saleAmount = params.data.saleAmount;
                let saleProportion = params.data.saleProportion;
                return `
                   <strong style="font-size:16px;">${name}</strong><br>
                    销售数量： ${saleCount}<br/>
                    销售金额: ${saleAmount}元<br/>
                    销售金额占比: ${saleProportion}<br/>
                `
              }
            },
            legend: {
              orient: 'vertical',
              left: 'right'
            },
            series: [
              {
                type: 'pie',
                radius: '80%',
                data: result,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }

          option && this.myChart.setOption(option);
        }
      })
    },
  }

}



</script>

<style scoped lang="scss">
.saleProductStatisticsDetail {
  .top {
    display: flex;
    align-items: flex-start;
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }

  .pagination {
    padding-bottom: 30px;
  }
}
</style>
